<header>
    <a href="javascript:void(0)" class="mob-menu-icon">
        <span></span>
        <span></span>
        <span></span>
    </a>
    <div class="right-section">
        <a href="javascript:void(0)" class="notifications">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12 2C10.3475 2 8.7627 2.66154 7.59421 3.8391C6.42572 5.01665 5.76927 6.61376 5.76927 8.27907C5.76927 11.4321 5.09976 13.4079 4.46919 14.5729C4.15295 15.1572 3.84274 15.5446 3.62247 15.7789C3.51215 15.8963 3.42392 15.9757 3.36815 16.0225C3.34025 16.046 3.32045 16.0613 3.31005 16.0691L3.30208 16.0749C3.05265 16.2464 2.94208 16.5615 3.02976 16.8533C3.11817 17.1476 3.38728 17.3488 3.69236 17.3488H20.3076C20.6127 17.3488 20.8818 17.1476 20.9702 16.8533C21.0579 16.5615 20.9474 16.2465 20.6979 16.0749L20.6899 16.0691C20.6795 16.0613 20.6597 16.046 20.6319 16.0225C20.5761 15.9757 20.4879 15.8963 20.3775 15.7789C20.1573 15.5446 19.847 15.1572 19.5308 14.5729C18.9002 13.4079 18.2307 11.4321 18.2307 8.27907C18.2307 6.61376 17.5743 5.01665 16.4058 3.8391C15.2373 2.66154 13.6525 2 12 2ZM18.3153 15.2411C18.4603 15.5089 18.6061 15.7456 18.7478 15.9535H5.25218C5.39385 15.7456 5.53974 15.5089 5.68473 15.2411C6.43877 13.8479 7.15387 11.6377 7.15387 8.27907C7.15387 6.98383 7.66445 5.74163 8.57327 4.82576C9.4821 3.90988 10.7147 3.39535 12 3.39535C13.2853 3.39535 14.5179 3.90988 15.4267 4.82576C16.3356 5.74163 16.8461 6.98383 16.8461 8.27907C16.8461 11.6377 17.5612 13.8479 18.3153 15.2411ZM9.84947 19.1293C9.67815 18.7848 9.26217 18.6455 8.92035 18.8181C8.57853 18.9908 8.44031 19.41 8.61162 19.7545C8.94273 20.4202 9.42431 20.9857 10.0179 21.3845C10.6126 21.784 11.2964 22 11.9998 22C12.7032 22 13.3869 21.784 13.9816 21.3845C14.5752 20.9857 15.0568 20.4202 15.3879 19.7545C15.5592 19.41 15.421 18.9908 15.0792 18.8181C14.7373 18.6455 14.3214 18.7848 14.15 19.1293C13.9183 19.5952 13.592 19.9692 13.2136 20.2235C12.8363 20.4769 12.4181 20.6047 11.9998 20.6047C11.5815 20.6047 11.1632 20.4769 10.7859 20.2235C10.4075 19.9692 10.0812 19.5952 9.84947 19.1293Z"
                    fill="black" />
            </svg>
        </a>
        <a href="javascript:void(0)" class="user-details">
            <img src="../../../../assets/images/headerPrPic.png" />
            <span class="email"> {{userInfo.username}}</span>
            <span class="arrow"></span>
        </a>
        <a href="javascript:void(0)" class="generic-btn logout" (click)="logout()">
            <span  class="primary" >logout</span>
        </a>
    </div>
</header>