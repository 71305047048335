import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { finalize, Observable, tap } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoaderService } from 'src/app/services/loader.service';
import { CommonService } from 'src/app/services/common.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService,
    private commonService:CommonService,
     private loaderService:LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // add authorization header with basic auth credentials if available
      const currentUser = this.authenticationService.currentUserValue;

      const skipLoader = request.headers.get('X-Skip-Loader') === 'true';

      if(!skipLoader){
        this.loaderService.startLoading();
      }

      if (currentUser && currentUser.token) {
          request = request.clone({
              setHeaders: { 
                  Authorization: `Bearer ${currentUser.token}`,
                 // "X-Tracking-Id":'w-'+this.commonService.trackingId
              }
          });
      }

      return next.handle(request).pipe(
        tap(res =>{
          if(res instanceof HttpResponse){
            
          }
        }),
        finalize(()=>{
          if(!skipLoader){
            this.loaderService.stopLoading();
          }
        })
      );
  }
}
