<div class="confirm-popup-wrapper">
    <div class="confirm-popup-box">
        <div class="head-text">Delete Asset?</div>
        <div class="desc-text">This action cannot be undone. Are you sure you want to delete this ?</div>
        <div class="confirm-popup-buttons-wrapper">
            <a href="javascript:void(0)" (click)="onActionClick('cancel')">
                Cancel
            </a>
            <a href="javascript:void(0)" class="primary" (click)="onActionClick('delete')">
                Delete
            </a>
        </div>
    </div>
</div>