import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PaginationDetails } from 'src/app/models/bread-crumb';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit , OnChanges {

  @Input() pageDetails:PaginationDetails;
  @Output() page:EventEmitter<PaginationDetails> = new EventEmitter<PaginationDetails>;
  pageSizeArray = [10,20,50,100];
  pages:number[] =[]
  endPage:number[] =[]
  currentPage:number = 1;
  totalPaginationToshow = 10;

  get pagesToShow(): number[] { 
    this.pages = [];
    this.endPage = [];
    this.currentPage = this.pageDetails.currentPage;
    this.pageDetails.totalPages  = Math.ceil(this.pageDetails.totalRecords / this.pageDetails.totalRecordPerPage);

    
    let startInd = Math.max(1, this.pageDetails.currentPage - 1);
    startInd = this.pageDetails.totalPages - this.totalPaginationToshow < startInd && startInd > this.totalPaginationToshow?
      this.pageDetails.totalPages - this.totalPaginationToshow:startInd;

    let endInd = Math.max(this.pageDetails.totalPages, this.pageDetails.currentPage);
    endInd  = this.pageDetails.totalPages < endInd ? this.pageDetails.totalPages : endInd;
    if((endInd- startInd) > 10){

    }
    for (let i = startInd; i <= endInd; i++) {
      
      if(i < (startInd + this.totalPaginationToshow)){
        this.pages.push(i);
      } 
      
      else if(i >= (endInd - 3) ){
        this.endPage.push(i);
      }
    }
    return this.pages;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.pageDetails =  changes.pageDetails.currentValue;
  }
  
  ngOnInit(): void {
    
  }

  goToPage(item){
    this.pageDetails.currentPage = item;
    this.pageDetails.totalRecordPerPage = this.totalPaginationToshow;
    this.currentPage = item;
    this.page.emit(this.pageDetails);
  }
  
  paginationToShow(item){
    this.totalPaginationToshow = item;
    this.goToPage(this.currentPage)
  }

}
