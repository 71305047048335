import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  
  public $commonLoadingSubject = new BehaviorSubject<boolean>(false);
  private count:number = 0;

  constructor() { }

  startLoading(){
    this.count++;
    this.$commonLoadingSubject.next(true);
  }

  stopLoading(){
    this.count--;
    if(this.count < 1){
      this.$commonLoadingSubject.next(false);
    }
  }

}
