<div class="breadcrumbs-wrapper" *ngIf="breadCrumbs && breadCrumbs.length > 0">
    <div class="breadcrumbs" >
        <ng-container *ngFor="let item of breadCrumbs; index as i">
            <a href="javascript:void(0)">{{item}}</a>
            <ng-container *ngIf="breadCrumbs.length != (i+1)">
                <span class="arrow" ></span> 
            </ng-container>
        </ng-container>
    </div>
    <div class="right-section-buttons" *ngIf="isShowHeaderAlso">
        <ng-container *ngIf="isShowHeaderAlso == 'prospect'">
            <a href="/assets/template/intelousai_lead_template.csv" class="generic-btn secondary">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.625 1.5C9.625 1.15482 9.34518 0.875 9 0.875C8.65482 0.875 8.375 1.15482 8.375 1.5V9.99112L5.27528 6.89139C5.0312 6.64731 4.63547 6.64731 4.39139 6.89139C4.14731 7.13547 4.14731 7.5312 4.39139 7.77528L8.55806 11.9419C8.61798 12.0019 8.68705 12.0471 8.76076 12.0776C8.83445 12.1081 8.91526 12.125 9 12.125C9.08474 12.125 9.16555 12.1081 9.23924 12.0776C9.31295 12.0471 9.38202 12.0019 9.44194 11.9419L13.6086 7.77528C13.8527 7.5312 13.8527 7.13547 13.6086 6.89139C13.3645 6.64731 12.9688 6.64731 12.7247 6.89139L9.625 9.99112V1.5ZM1.5 10.875C1.84518 10.875 2.125 11.1548 2.125 11.5V14.8333C2.125 15.1096 2.23475 15.3746 2.4301 15.5699C2.62545 15.7653 2.8904 15.875 3.16667 15.875H14.8333C15.1096 15.875 15.3746 15.7653 15.5699 15.5699C15.7653 15.3746 15.875 15.1096 15.875 14.8333V11.5C15.875 11.1548 16.1548 10.875 16.5 10.875C16.8452 10.875 17.125 11.1548 17.125 11.5V14.8333C17.125 15.4411 16.8836 16.024 16.4538 16.4538C16.024 16.8836 15.4411 17.125 14.8333 17.125H3.16667C2.55888 17.125 1.97598 16.8836 1.54621 16.4538C1.11644 16.024 0.875 15.4411 0.875 14.8333V11.5C0.875 11.1548 1.15482 10.875 1.5 10.875Z" fill="#14161D"></path>
                </svg>
                <span>Download Leads Template</span>
            </a>
            <a  class="generic-btn secondary" (click)="onFileUpload()">
                <input type="file" id="handleLeadImportFile" class="custom-file-input me-3" (change)="handleLeadImport($event)" hidden
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                Import Leads</a>
            <a  class="generic-btn" (click)="IsProspectSet()">Create Lead</a>
        </ng-container>
        <ng-container *ngIf="isShowHeaderAlso == 'edit-persona'">
            <a  class="generic-btn secondary" (click)="refreshData()">
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15.9846 1.54138C15.1958 1.54128 14.4393 1.85453 13.8815 2.4122L12.1342 4.15989C12.1076 4.17916 12.0821 4.20078 12.0581 4.22476C12.0341 4.24877 12.0125 4.27425 11.9932 4.30091L2.76004 13.5361C2.49418 13.8013 2.29756 14.1278 2.18748 14.4868L2.18697 14.4885L1.08614 18.1152L1.08536 18.1177C1.03152 18.2979 1.02745 18.4893 1.07359 18.6716C1.11973 18.8539 1.21436 19.0203 1.34744 19.1532C1.48051 19.286 1.64707 19.3804 1.82944 19.4262C2.01181 19.4721 2.20319 19.4677 2.38328 19.4136L6.01273 18.313C6.37157 18.2038 6.69813 18.0083 6.96377 17.7435L16.1984 8.50754C16.2253 8.4881 16.2511 8.46624 16.2754 8.44197C16.2996 8.41768 16.3215 8.39189 16.341 8.3649L18.087 6.61861C18.6448 6.06094 18.9582 5.30455 18.9583 4.51582C18.9584 3.72705 18.6451 2.97055 18.0874 2.41273C17.5298 1.85491 16.7733 1.54148 15.9846 1.54138ZM15.8277 7.11039L17.2031 5.73479C17.5264 5.41151 17.7082 4.97292 17.7083 4.51567C17.7083 4.05842 17.5267 3.61987 17.2034 3.2965C16.8802 2.97314 16.4417 2.79144 15.9844 2.79138C15.5272 2.79132 15.0886 2.97291 14.7652 3.2962L13.3895 4.67224L15.8277 7.11039ZM12.5057 5.55623L3.64299 14.421C3.52222 14.5414 3.43287 14.6896 3.38278 14.8526L2.39599 18.1035L5.649 17.1171C5.81204 17.0675 5.96042 16.9787 6.08114 16.8584L14.9438 7.99434L12.5057 5.55623Z"
                    fill="#14161D" />
                </svg>
                <span>Edit Campaigns</span>
            </a>
        </ng-container>
        <ng-container *ngIf="isShowHeaderAlso == 'abm'">
            <a  class="generic-btn secondary" (click)="IsProspectSet()">Create Account</a>
        </ng-container>
    </div>
</div> 