import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userInfo:any
  constructor(private auth:AuthenticationService,private router:Router){}

  ngOnInit(): void {
    this.auth.currentUser.subscribe(res=>{
      this.userInfo = res;
    })
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/login']); 
  }

}
