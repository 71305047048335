import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  selectedMenu:string;
  selectedSubMenu:string;
  urlDict:{[key:string]:string};
  currentUser: User;
  
  constructor(private router:Router,private authenticationService: AuthenticationService){ 
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    let urls = this.router.url.split("/");
    this.selectedMenu = urls[1] == "campaign-management"? "campaigns":urls[1];
    if(urls.length > 2){
      this.selectedSubMenu = this.selectedMenu === "campaigns"? (urls[2] == "list"?"campaigns":"content") : urls[2];
    } 
    
       
  }
  

  onMenuClick(menu:string,subMenu:string){
    if(this.selectedMenu != menu){
      this.selectedMenu = menu;
    }
    if(subMenu != null){
      this.selectedSubMenu = subMenu;
    }
  }
}


