export class User {
    id: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    token?: string;
    isAdmin?:boolean;
    isSuperAdmin?:boolean;
}

export class ComboData{
    constructor(text:string,value:string){
        this.value = value;
        this.text = text;
    }
    id:number;
    text:string;
    value:string;
    selectd:boolean = false;
}
