import { Component } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  isLoader:boolean = true;
  constructor(private loaderService:LoaderService){}

  ngOnInit() {
    this.loaderService.$commonLoadingSubject.subscribe(res=>{
      this.isLoader = res;
    })
  }

}
