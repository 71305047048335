import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ComboData } from 'src/app/models/user';

@Component({
  selector: 'app-combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.scss']
})
export class ComboBoxComponent {
  showList:boolean = false;
  @Input() listData:ComboData[] = [];
  @Input() control!: FormControl; // FormControl passed from parent
  @Input() isFormString: boolean = false;
  listDataOg:ComboData[] = [];
  get selectedItems():ComboData[]{
    if(this.listData && this.listData.length > 0){
      return this.listData.filter(v=>v.selectd == true)
    }
    return  []
  };
  isSelectAll:boolean = false;

  constructor(private cdr:ChangeDetectorRef){}

  ngOnInit(): void {
    this.listDataOg = this.listData;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.listData = changes.listData.currentValue;
    this.listDataOg = this.listData; 
    this.setValue()
  }

  onClick(){
    this.showList = !this.showList;
  }

  setValue() {
    if(this.control){ 
      let selectdArray = []
      this.selectedItems.forEach(v=>{
        selectdArray.push(v.value)
      })
      if(this.isFormString){
        let  selectdString = selectdArray.join(', ');
        this.control.setValue(selectdString); 
      }else{
        this.control.setValue(selectdArray); 
      }
      
      this.control.markAsDirty();
      this.control.markAsTouched();
    }
  }

  onSelectClick(item:ComboData,isRemove:boolean = false){
    if(isRemove){
      item.selectd = false;
      //this.selectedItems = this.selectedItems.filter(i=> i.text != item.text)
    }else{
      item.selectd = !item.selectd;
      if(item.selectd){
        this.selectedItems.push(item);
        document?.getElementById("ch_"+item.text)?.click();
      }else{
        //this.selectedItems = this.selectedItems.filter(i=> i.text != item.text);
        document?.getElementById("ch_"+item.text)?.click();
      } 
    }
    this.setValue();
  }

  onClickOutSide(){
    this.showList = false;
  }

  onKeyUp(event){
    let typedValue = event.target.value; // Get
    this.listData = this.listDataOg.filter((v) => v.text.toLowerCase().includes(typedValue));
  }

  onSelectAll(){
    this.isSelectAll = true;
    this.listData.forEach(v=>{
      v.selectd = true;
    }); 
  }

  onUnSelectAll(){
    this.isSelectAll = false;
    this.listData.forEach(v=>{
      v.selectd = false;
    }); 
  }

}
