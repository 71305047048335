<div class="pagination-wrapper" *ngIf="pageDetails.totalRecords > 0">
    <a href="javascript:void(0)" class="left-arrow" [ngClass]="{'disabled':currentPage == 1}">
        <span class="arrow left"></span>
    </a> 
    <!-- <span class="dots">
        <div class="each-dot"></div>
        <div class="each-dot"></div>
        <div class="each-dot"></div>
    </span> --> 
    <a *ngFor="let page of pagesToShow" href="javascript:void(0)" (click)="goToPage(page)" [class.active]="currentPage === page">
        {{ page }}
    </a>
    
    <span class="dots" *ngIf="pages.length > this.totalPaginationToshow">
        <div class="each-dot"></div>
        <div class="each-dot"></div>
        <div class="each-dot"></div>
    </span>
    <a *ngFor="let page of endPage" href="javascript:void(0)" (click)="goToPage(page)" [class.active]="currentPage === page">
        {{ page }}
    </a>
    <a href="javascript:void(0)" class="right-arrow">
        <span class="arrow right"></span>
    </a>
    <select (change)="paginationToShow($event.target?.value)">
        <option *ngFor="let item of pageSizeArray" [value]="item">{{item}} Items</option>
    </select>
</div>
