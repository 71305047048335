import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {

  @Output() onAction:EventEmitter<{ action:string, data:any}> = new EventEmitter<{ action:string, data:any }>();


  onActionClick(event:string){
    this.onAction.emit({ action: event , data: null });
  }



}
