import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { SnackBarComponent } from '../shared/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private snackBarComponentRef: any;

  constructor(
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef
  ) {}

  show(message: string,color: string = '#007bff', duration: number = 3000): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(SnackBarComponent);
    this.snackBarComponentRef = factory.create(this.injector);

    // Attach the component to the application
    this.appRef.attachView(this.snackBarComponentRef.hostView);

    // Set the message and duration for the snackbar
    this.snackBarComponentRef.instance.showMessage(message, duration);

    // Set the background color of the snackbar dynamically
    this.snackBarComponentRef.instance.snackbarColor = color;

    // Add the component to the body
    const domElem = (this.snackBarComponentRef.hostView as any).rootNodes[0];
    document.body.appendChild(domElem);

    // Clean up after the snackbar duration
    setTimeout(() => {
      this.appRef.detachView(this.snackBarComponentRef.hostView);
      domElem.remove();
    }, duration);
  }
}
