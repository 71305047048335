import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss']
})
export class BreadCrumbsComponent implements OnInit {

  breadCrumbs:string[];
  isShowHeaderAlso:string;
  
  constructor(
    private commonService:CommonService,
    private apiService:ApiService,
    private snackbarService:SnackbarService
  ){
    commonService.$isBreadcrum.subscribe((_)=>{
      this.breadCrumbs = commonService.breadcrum;
      this.isShowHeaderAlso = commonService.isShowHeaderAlso;
    });
  }


  ngOnInit(): void { 
  }
  onFileUpload(){
    var doc= document.getElementById("handleLeadImportFile");
    doc.click();
  }

  handleLeadImport(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const formData: FormData = new FormData();
      formData.append('file', file, file.name); 
      this.apiService.uploadProspectLead(formData)
        .subscribe((res: any) => {  
          this.snackbarService.show('CSV has been uploaded.');
        },
          (err) => { 
            this.snackbarService.show('Something went wrong try again.');
          },
        );
    }
  }

  IsProspectSet(){
    this.commonService.IsProspectSet();
  }

  refreshData(){
    this.commonService.refreshData();
  }

}
