import { Component } from '@angular/core';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
  message: string = '';
  isVisible: boolean = false;
  snackbarColor: string = '#007bff'; // Default color
  duration: number = 3000; // default duration

  showMessage(message: string, duration: number): void {
    if(!this.message){
      this.message = message;
      this.isVisible = true;
      this.duration = duration;
  
      setTimeout(() => {
        this.isVisible = false;
      }, duration);
    }else{
      setTimeout(() => {
        this.showMessage(message,duration);
      }, duration);
    }
  }
}