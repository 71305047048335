import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs'; 
import { CommonService } from 'src/app/services/common.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(  private snackbarService:SnackbarService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(catchError(err => {
          if (err.status === 401) {
              // auto logout if 401 response returned from api
              // this.authenticationService.logout();
              this.snackbarService.show('Unauthorized user.',"red");
              // location.reload()
          }
          const error = err.error.message || err.statusText;
          if (err.error.message && err.status === 400) {
            this.snackbarService.show(error,"red");
          } else {
              this.snackbarService.show('Something went wrong try again.',"red");
          }
          return throwError(error);
      }))
  }
}