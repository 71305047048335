import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs'; 
import { ComboData } from '../models/user';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  breadcrum:string[] = [];
  isShowHeaderAlso:string=null;

  private _trackingId;
  public get trackingId(){
    return this._trackingId;
  }

  private _isBreadcrum: Subject<boolean> = new Subject();
  public $isBreadcrum: Observable<boolean> = this._isBreadcrum.asObservable();
  
  private _refresh: Subject<boolean> = new Subject();
  public $refreshData: Observable<boolean> = this._refresh.asObservable();
  
  private _isProspect: Subject<boolean> = new Subject();
  public $isProspect: Observable<boolean> = this._isProspect.asObservable();

  constructor(private snack:SnackbarService) { }

  openSnacbar(val:string){
    this.snack.show(val);
  }

  breadCrumSet(){
    this._isBreadcrum.next(true);
  }

  IsProspectSet(){
    this._isProspect.next(true);
  }

  getChangedProperties(formGroup: FormGroup): any {
    const changedProperties: any = {};
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (control?.dirty) {
        changedProperties[key] = control.value;
      }
    });
    return changedProperties;
  }

  refreshData(){
    this._refresh.next(true);
  }

  serRandomTrackingId(){
    this._trackingId = `${Date.now()}-${Math.random().toString(36).substring(2, 8)}`; 
  }

  transformToComboData(lstData:string[],dataObject:ComboData[]){
    lstData.forEach(v=>{
      dataObject.push(new ComboData(v,v));
    })
  }

}
