import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs'; 
import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http"; 
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private readonly BASE_URL: string;
  private API_VERSION = "v1";

  constructor(private http: HttpClient) {
    this.BASE_URL = environment.apiUrls.baseUrl;
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  signUp(userInfo) {
    const url = `${this.BASE_URL}/${this.API_VERSION}/auth/signup`;
    const body = userInfo;
    return this.http.post<any>(url, body);
  }

  signIn(userInfo) {
    const url = `${this.BASE_URL}/${this.API_VERSION}/auth/login`;
    const body = userInfo;
    return this.http.post<any>(url, body).pipe(
      map((user) => {
        // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
        user.username = userInfo["userName"];
        localStorage.setItem("currentUser", JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
  }
}
