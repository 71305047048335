import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './layout/menu/menu.component';
import { BreadCrumbsComponent } from './layout/bread-crumbs/bread-crumbs.component';
import { HeaderComponent } from './layout/header/header.component';
import { RouterModule } from '@angular/router';
import { PaginationComponent } from './pagination/pagination.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { LoaderComponent } from './loader/loader.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { ComboBoxComponent } from './combo-box/combo-box.component';
import { ClickOutSideDirective } from './directives/click-out-side.directive'; 

const modules = [
  CommonModule,
  RouterModule,
  FormsModule, 
  ReactiveFormsModule
]

const components = [
  MenuComponent,
  BreadCrumbsComponent,
  HeaderComponent,
  PaginationComponent,
  ConfirmationComponent,
  LoaderComponent,
  SnackBarComponent,
  ComboBoxComponent,
  ClickOutSideDirective
]

@NgModule({
  declarations: [
    components, 
  ],
  imports: [
    ...modules 
  ],
  exports:[
    ...modules, ...components
  ]
})
export class IntelousModule { }
